import Render from './Render.js'
import {html} from '@isceco/widget-library2/external/lit'
import Enum from '../Enum/Enum.js'

export default class CompanyRender extends Render {
  get company() {
    return this.metadaten.Companies.Company
  }

  render = () => html`
    <div class="group">
      <div class="header row">
        ${this.i18n.translate('label.companies')}
      </div>
      ${this.company?.map((_, index) => this.renderCompany(index))}
      <div class="edit">
        <isceco-button icon="plus"
                       text="${this.i18n.translate('label.companies.add')}"
                       variant="tertiary"
                       @click="${_ => this.addParentCompany()}"
        ></isceco-button>
      </div>
    </div>
  `

  renderCompany = index => {
    let header
    let remove = ''
    if (index === 0) {
      header = 'label.companies.company'
    } else {
      header = 'label.companies.parentcompany'
      remove = html`
        <div class="edit">
          <isceco-button icon="x"
                         text="${this.i18n.translate('label.remove')}"
                         variant="tertiary"
                         @click="${_ => this.removeParentCompany(index)}"
          ></isceco-button>
        </div>
      `
    }
    const buildKey = path => `Companies.Company.[${index}].${path}`
    const exclude = `[${index}].`

    return html`
      <div class="section" id="company-row-${index}">
        <div class="collapsible row" @click="${e => this.rotatePriority(e)}">
          ${this.i18n.translate(header, {index: index})}
          ${remove}
        </div>
        ${this.renderCompanyId(index, buildKey, 'IdsInformation', exclude)}
        ${this.renderCompanyId(index, buildKey, 'ISABIdInformation', exclude)}
        ${this.text({exclude: exclude, key: buildKey('Name'), maxLength: 100, priority: true, required: true})}
        ${this.text({exclude: exclude, key: buildKey('AdditionalName'), maxLength: 100})}
        ${this.text({exclude: exclude, key: buildKey('Street'), maxLength: 100, priority: true})}
        ${this.text({exclude: exclude, key: buildKey('Street2'), maxLength: 100})}
        ${this.text({exclude: exclude, key: buildKey('Zip'), maxLength: 100, priority: true})}
        ${this.text({exclude: exclude, key: buildKey('City'), maxLength: 100, priority: true, required: true})}
        ${this.select({exclude: exclude, key: buildKey('Country'), items: Enum.country(), priority: true, required: true})}
        ${this.text({exclude: exclude, key: buildKey('Phone'), maxLength: 30, priority: true})}
        ${this.text({exclude: exclude, key: buildKey('Fax'), maxLength: 30})}
        ${this.text({exclude: exclude, key: buildKey('Mail'), maxLength: 200, priority: true})}
        ${this.text({exclude: exclude, key: buildKey('UID'), maxLength: 30})}
        ${this.text({exclude: exclude, key: buildKey('WebAddress'), maxLength: 100})}
        ${this.select({exclude: exclude, key: buildKey('CorrespondenceLanguage'), items: Enum.language()})}
      </div>
    `
  }

  removeParentCompany = index => {
    this.remove(`#company-row-${index}`)
  }

  addParentCompany = () => {
    this.company.push({
      IdsInformation: {
        IdInformation: {}
      }
    })
    this.redraw()
  }

  renderCompanyId = (index, buildKey, key, parent) => {
    const companyIdKey = buildKey(`${key}.IdInformation.CompanyId`)
    if (this.company[index][key]) {
      const participantIdKey = buildKey(`${key}.IdInformation.ParticipantId`)
      return this.entry({
        content: html`
          <isceco-text-input inline
                             class="required"
                             placeholder="CompanyID"
                             ?readonly="${this.read}"
                             maxlength="100"
                             name="${companyIdKey}"
          ></isceco-text-input>
          <isceco-text-input inline
                             placeholder="ParticipantID"
                             ?readonly="${this.read}"
                             maxlength="100"
                             name="${participantIdKey}"
          ></isceco-text-input>
          <div class="edit">
            <isceco-button icon="x"
                           text="${this.i18n.translate('label.remove')}"
                           variant="tertiary"
                           @click="${_ => this.removeIdInformation(index, key)}"
            ></isceco-button>
          </div>
        `, key: companyIdKey, exclude: parent
      })
    } else {
      return this.entry({
        content: html`
          <div class="edit">
            <isceco-button icon="plus"
                           text="${this.i18n.translate('label.companies.idinformation.add')}"
                           variant="tertiary"
                           @click="${_ => this.addIdInformation(index, key)}"
            ></isceco-button>
          </div>
        `, key: companyIdKey, exclude: parent
      })
    }
  }

  removeIdInformation = (index, key) => {
    delete this.company[index][key]
    this.redraw()
  }

  addIdInformation = (index, key) => {
    this.company[index][key] = {
      IdInformation: {}
    }
    this.redraw()
  }
}
