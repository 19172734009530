import Render from './Render.js'
import {html} from '@isceco/widget-library2/external/lit'
import Enum from '../Enum/Enum.js'

export default class DossierRender extends Render {
  get dossier() {
    return this.metadaten.Dossier
  }

  get header() {
    return this.metadaten.Metadaten
  }

  render = () => html`
    <div class="group">
      <div class="header row">
        ${this.i18n.translate('label.datenfile')} ${this.header.name}
      </div>
      <div class="section">
        <div class="collapsible row" @click="${e => this.rotatePriority(e)}">
          ${this.i18n.translate('label.dossier')}
        </div>
        ${this.text({key: 'Dossier.CaseNr', maxLength: 40, info: true})}
        ${this.text({key: 'Dossier.CaseGUID', maxLength: 100, info: true})}
        ${this.select({key: 'Dossier.ControlType', items: Enum.controlType(), priority: true, required: true})}
        ${this.select({key: 'Dossier.CaseForm', items: Enum.caseForm(), priority: true, required: true})}
        ${this.text({key: 'Dossier.CEA', maxLength: 40, priority: true, required: true})}
        ${this.date({key: 'Dossier.ControlDate', priority: true, required: true})}
        ${this.entry({content: this.renderFileReferences(), key: 'Dossier.FileReferences', info: true})}
      </div>
      <div class="section">
        <div class="collapsible row" @click="${e => this.rotatePriority(e)}">
          ${this.i18n.translate('label.dossier.controlsite')}
        </div>
        ${this.number({key: 'Dossier.ControlSite.CommunityNumber', max: 9999, info: true})}
        ${this.select({key: 'Dossier.ControlSite.Canton', items: Enum.canton(), priority: true})}
        ${this.text({key: 'Dossier.ControlSite.Street', maxLength: 57, priority: true})}
        ${this.text({key: 'Dossier.ControlSite.Street2', maxLength: 25, info: true})}
        ${this.number({key: 'Dossier.ControlSite.Zip', max: 999999, priority: true})}
        ${this.text({key: 'Dossier.ControlSite.City', maxLength: 39, priority: true, required: true})}
      </div>
    </div>
  `

  renderFileReferences = () => {
    const fileRef = this.dossier.FileReferences?.map((_, index) => this.renderFileReference(index)) ?? []
    fileRef.push(html`
      <div class="edit">
        <isceco-button icon="plus"
                       variant="tertiary"
                       @click="${_ => this.addFileReference()}"
                       text="${this.i18n.translate('label.dossier.filereferences.add')}"
        ></isceco-button>
      </div>
    `)
    return fileRef
  }

  renderFileReference = index => {
    const key = `Dossier.FileReferences.[${index}]`
    return html`
      <div class="inline" id="file-reference-row-${index}">
        <isceco-text-input inline
                           ?readonly="${this.read}"
                           maxlength="100"
                           name="${key}"
        ></isceco-text-input>
        <div class="edit" style="flex: 0">
          <isceco-button icon="x"
                         variant="tertiary"
                         @click="${_ => this.removeFileReference(index)}"
          ></isceco-button>
        </div>
      </div>
    `
  }

  removeFileReference = index => {
    this.remove(`#file-reference-row-${index}`)
  }

  addFileReference = () => {
    if (!this.dossier.FileReferences) {
      this.dossier.FileReferences = []
    }
    this.dossier.FileReferences.push('')
    this.redraw()
  }
}
