import Flam from '../MainView/Flam.js'

export default class ViewFilter {
  constructor() {
    this.roles = {
      user: window.keycloak.hasRealmRole(Flam.ROLES().user),
      superuser: window.keycloak.hasRealmRole(Flam.ROLES().superuser),
      admin: window.keycloak.hasRealmRole(Flam.ROLES().admin),
      betrieb: window.keycloak.hasRealmRole(Flam.ROLES().betrieb),
    }
  }

  viewAllowed(name) { // NOSONAR complexity
    switch (name) {
      case 'Dossier':
        return this.roles.admin
      case 'Empfangen':
      case 'Gesendet':
      case 'Senden':
        return this.roles.user || this.roles.superuser
      case 'Profil':
        return this.roles.user || this.roles.superuser || this.roles.admin
      case '':
      case 'About':
      case 'Landing':
      case 'Organisation':
      case 'OrganisationDetail':
        return this.roles.user || this.roles.superuser || this.roles.admin || this.roles.betrieb
      default:
        return false
    }
  }

  viewsAllowed(names) {
    return names.filter(name => this.viewAllowed(name))
  }
}
