export default class MetadatenDto {
  constructor(metadaten) {
    this.Metadaten = {
      id: metadaten.id,
      organisation: metadaten.organisation,
      path: metadaten.path,
      name: metadaten.name,
      changed: metadaten.changed,
      valid: metadaten.valid,
      art: metadaten.art,
      createdBy: metadaten.created_by
    }
    if (Object.keys(metadaten).length === 0) {
      this.empty()
    } else {
      const message = JSON.parse(metadaten.content).Message
      this.LastSender = message.LastSender
      this.Dossier = message.Dossier
      this.Companies = message.Companies
      this.Employees = message.Employees
      this.PBK = message.PBK
      this.preProcess(message)
    }
  }

  empty() {
    this.Dossier = {}
    this.Companies = {Company: [{}]}
    this.Employees = {Employee: [{}]}
  }

  preProcess(message) {
    this.Dossier.ControlDate = this.preProcessDate(message.Dossier.ControlDate)
    this.Companies.Company = this.preProcessCompanies(message.Companies.Company)
    this.Employees.Employee.forEach(employee => {
      employee.EmployeeInfo.DateOfBirth = this.preProcessDate(employee.EmployeeInfo.DateOfBirth)
      employee.Zemis.DateOfBirth = this.preProcessDate(employee.Zemis.DateOfBirth)
      employee.Zemis.Egeftaregulationdate = this.preProcessDate(employee.Zemis.Egeftaregulationdate)
      employee.Zemis.CaptureDate = this.preProcessDate(employee.Zemis.CaptureDate)
      employee.Zemis.CancellationDate = this.preProcessDate(employee.Zemis.CancellationDate)
      employee.Zemis.ModificationDate = this.preProcessDate(employee.Zemis.ModificationDate)
      employee.Zemis.RegistrationDate = this.preProcessDate(employee.Zemis.RegistrationDate)
      employee.Zemis.UpdateDate = this.preProcessDate(employee.Zemis.UpdateDate)
      employee.Zemis.WorkPeriods?.forEach(workPeriod => {
        workPeriod.From = this.preProcessDate(workPeriod.From)
        workPeriod.Until = this.preProcessDate(workPeriod.Until)
      })
      employee.Facts.IdValidity = this.preProcessDate(employee.Facts.IdValidity)
      employee.Facts.DeploymentFrom = this.preProcessDate(employee.Facts.DeploymentFrom)
      employee.Facts.DeploymentTo = this.preProcessDate(employee.Facts.DeploymentTo)
      employee.Facts.EntryDate = this.preProcessDate(employee.Facts.EntryDate)
      employee.Facts.EmployeeSince = this.preProcessDate(employee.Facts.EmployeeSince)
    })
    if (message.PBK?.Decision) {
      this.PBK.Decision.DateResolution = this.preProcessDate(message.PBK.Decision.DateResolution)
      this.PBK.Decision.AppealReceiptDate = this.preProcessDate(message.PBK.Decision.AppealReceiptDate)
      this.PBK.Decision.DateAppealDec = this.preProcessDate(message.PBK.Decision.DateAppealDec)
    }
  }

  preProcessCompanies(company) {
    let processing = company
    const list = [this.preProcessCompany(processing)]
    while (processing.ParentCompany) {
      processing = processing.ParentCompany
      list.push(this.preProcessCompany(processing))
    }
    return list
  }

  preProcessCompany(company) {
    const clone = structuredClone(company)
    delete clone.ParentCompany
    return clone
  }

  preProcessDate(date) {
    return date ? `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6)}` : date
  }

  static json(obj) {
    const message = structuredClone(obj)
    delete message.Metadaten
    message.Dossier.ControlDate = this.postProcessDate(message.Dossier.ControlDate)
    message.Dossier.FileReferences = this.postProcessList(message.Dossier.FileReferences)
    message.Companies.Company = this.postProcessCompanies(message.Companies.Company)
    message.Employees.Employee = this.postProcessList(message.Employees.Employee)
    message.Employees.Employee.forEach(employee => {
      employee.EmployeeInfo.DateOfBirth = this.postProcessDate(employee.EmployeeInfo.DateOfBirth)
      employee.Zemis.DateOfBirth = this.postProcessDate(employee.Zemis.DateOfBirth)
      employee.Zemis.Egeftaregulationdate = this.postProcessDate(employee.Zemis.Egeftaregulationdate)
      employee.Zemis.CaptureDate = this.postProcessDate(employee.Zemis.CaptureDate)
      employee.Zemis.CancellationDate = this.postProcessDate(employee.Zemis.CancellationDate)
      employee.Zemis.ModificationDate = this.postProcessDate(employee.Zemis.ModificationDate)
      employee.Zemis.RegistrationDate = this.postProcessDate(employee.Zemis.RegistrationDate)
      employee.Zemis.UpdateDate = this.postProcessDate(employee.Zemis.UpdateDate)
      employee.Zemis.WorkPeriods = this.postProcessList(employee.Zemis.WorkPeriods)
      employee.Zemis.WorkPeriods?.forEach(workPeriod => {
        workPeriod.From = this.postProcessDate(workPeriod.From)
        workPeriod.Until = this.postProcessDate(workPeriod.Until)
      })
      employee.Facts.IdValidity = this.postProcessDate(employee.Facts.IdValidity)
      employee.Facts.DeploymentFrom = this.postProcessDate(employee.Facts.DeploymentFrom)
      employee.Facts.DeploymentTo = this.postProcessDate(employee.Facts.DeploymentTo)
      employee.Facts.EntryDate = this.postProcessDate(employee.Facts.EntryDate)
      employee.Facts.EmployeeSince = this.postProcessDate(employee.Facts.EmployeeSince)
    })
    if (message.PBK?.Decision) {
      message.PBK.Decision.DateResolution = this.postProcessDate(message.PBK.Decision.DateResolution)
      message.PBK.Decision.AppealReceiptDate = this.postProcessDate(message.PBK.Decision.AppealReceiptDate)
      message.PBK.Decision.DateAppealDec = this.postProcessDate(message.PBK.Decision.DateAppealDec)
    }
    this.removeNullValues(message)
    return {
      id: obj.Metadaten.id,
      organisation: obj.Metadaten.organisation,
      path: obj.Metadaten.path,
      name: obj.Metadaten.name,
      changed: obj.Metadaten.changed,
      valid: obj.Metadaten.valid,
      art: obj.Metadaten.art,
      created_by: obj.Metadaten.createdBy,
      content: JSON.stringify({Message: message})
    }
  }

  static postProcessCompanies(list) {
    const clean = this.postProcessList(list)
    const company = clean[0]
    let processing = company
    for (let i = 1; i < clean.length; i++) {
      processing.ParentCompany = clean[i]
      processing = processing.ParentCompany
    }
    return company
  }

  static postProcessDate(date) {
    return date ? date.replaceAll('-', '') : date
  }

  static postProcessList(list) {
    return list ? list.filter(v => v) : list
  }

  static removeNullValues(obj) {
    const isArray = obj instanceof Array
    for (const key in obj) {
      if (obj[key] === null) {
        isArray ? obj.splice(parseInt(key), 1) : delete obj[key]
      } else if (typeof obj[key] == 'object') {
        this.removeNullValues(obj[key])
      }
    }
  }
}
