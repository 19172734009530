import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/MultiselectFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/DateFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/TypeaheadFilter.js'
import WebComponent from '../../WebComponent.js'
import ActiveRow from './ActiveRow.js'
import DossierService from '../Dossier/DossierService.js'
import DossierDTO from '../Dossier/DossierDTO.js'
import DossierColumns from '../Dossier/DossierColumns.js'
import OrganisationService from '../Organisation/OrganisationService.js'
import OrganisationTypeaheadService from '../Organisation/OrganisationTypeaheadService.js'
import Dossierstatus from './Dossierstatus.js'
import Meldeart from './Meldeart.js'

export default class Dossier extends WebComponent {

  get translationFile() {
    return './views/Dossier/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.reload()
  }

  reload() {
    const organisationService = new OrganisationService()
    const dossierService = new DossierService()

    Promise.all([
      organisationService.getBackendListUrl(),
      dossierService.getBackendListUrl()
    ])
      .then(([orgUrl, url]) => {
        this.url = url
        this.orgUrl = orgUrl
        this.render()
      })
      .catch(_ => {
        this.url = ''
        this.orgUrl = ''
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title id="page-title" text="${this.i18n.translate('dossier.titel')}"></isceco-title>
      <p>${this.i18n.translate('dossier.beschreibung')}</p>

      <isceco-filter-group>
        <isceco-string-filter label="${this.i18n.translate('table.header.dossiernummer')}"
                              key="code"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-multiselect-filter label="${this.i18n.translate('table.header.meldeart')}"
                                   .items="${Meldeart.OPTIONS}"
                                   .i18n="${this.i18n}"
                                   key="art"
        ></isceco-multiselect-filter>
        <isceco-typeahead-filter label="${this.i18n.translate('table.header.sender')}"
                                 key="sender"
                                 url="${this.orgUrl}"
                                 maxresults="50"
                                 .service="${OrganisationTypeaheadService}"
                                 .itemMapper="${org => ({value: org.code, name: org.name})}"
        ></isceco-typeahead-filter>
        <isceco-typeahead-filter label="${this.i18n.translate('table.header.empfaenger')}"
                                 key="empfaenger"
                                 url="${this.orgUrl}"
                                 maxresults="50"
                                 .service="${OrganisationTypeaheadService}"
                                 .itemMapper="${org => ({value: org.code, name: org.name})}"
        ></isceco-typeahead-filter>
        <isceco-string-filter label="${this.i18n.translate('table.header.datenfile')}"
                              key="metadaten"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-string-filter label="${this.i18n.translate('table.header.pdffile')}"
                              key="nutzdaten"
                              minchars="1"
        ></isceco-string-filter>
        <isceco-date-filter label="${this.i18n.translate('table.header.sendedatum')}"
                            key="created"
        ></isceco-date-filter>
        <isceco-date-filter label="${this.i18n.translate('table.header.bearbeitetdatum')}"
                            key="changed"
        ></isceco-date-filter>
        <isceco-multiselect-filter label="${this.i18n.translate('table.header.status')}"
                                   .items="${Dossierstatus.OPTIONS}"
                                   .i18n="${this.i18n}"
                                   key="status"
        ></isceco-multiselect-filter>
      </isceco-filter-group>

      <isceco-list id="flamfrontend-dossiers"
                   url="${this.url}"
                   .i18n="${this.i18n}"
                   .rowVariant="${new ActiveRow()}"
                   .dtoMapper="${this.toDossier}"
                   .columns="${[
                     DossierColumns.CODE_COLUMN(),
                     DossierColumns.MELDEART_COLUMN(),
                     DossierColumns.SENDER_COLUMN(),
                     DossierColumns.EMPFAENGER_COLUMN(),
                     DossierColumns.METADATEN_COLUMN_READONLY(),
                     DossierColumns.NUTZDATEN_COLUMN_READONLY(),
                     DossierColumns.SENDEDATUM_COLUMN(),
                     DossierColumns.BEARBEITETDATUM_COLUMN(),
                     DossierColumns.STATUS_COLUMN('status.archived')
                   ]}"
                   sort="changed"
      ></isceco-list>
    `
  }

  toDossier = row => new DossierDTO(row)
}

customElements.define('flam-dossiers', Dossier)
