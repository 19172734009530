import BaseService from '../../BaseService.js'

export default class OrganisationService extends BaseService {

  constructor() {
    super('organisation')
  }

  async listWithMax(max) {
    await this.getBackendListUrl().then(url => this.serviceurl = url)
    return this.checkError(
      fetch(`${this.serviceurl}?max=${max}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }
}
